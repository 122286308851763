import _get from "lodash/get";
import _merge from "lodash/merge";
import _uniqueId from "lodash/uniqueId";

import { n8nPath, n8n2Path, WEB_API_PATH, REQUEST_SIGNATURE_HEADER_NAME, GLOBAL_CONFIG_KEY } from "../constants/common";
import { getJWT, setJWT } from "./auth";
import { sha256 } from "./crypto";

export const JsonRpcErrorCode = {
  Redirect: 303,
  Unauthorized: 401,

  AccessDenied: 403,
  NotFound: 404,
  Conflict: 409,

  ParseError: -32700,
  InvalidRequest: -32600,
  MethodNotFound: -32601,
  InvalidParams: -32602,
};

export class JsonRpcError extends Error {}

export function wrapJsonRpcError(e) {
  return new JsonRpcError(JSON.stringify(e));
}

export function unwrapJsonRpcError(e) {
  if (e instanceof JsonRpcError) {
    return JSON.parse(e.message);
  }

  return null;
}

function withToken(params) {
  const result = { ...params };
  const jwt = getJWT();
  if (jwt) {
    result.token = jwt;
  }
  return result;
}

async function makeSignature(body, secret) {
  return await sha256(`${body}${secret}`);
}

function sortObjectKeys(obj) {
  return Object.fromEntries(Object.entries(obj).sort());
}

export async function fetchWithN8n(method, params = {}, options = {}, n8nVersion = 1) {
  try {
    const id = _uniqueId(Date.now());

    const body = JSON.stringify(
      sortObjectKeys({
        id,
        jsonrpc: "2.0",
        method,
        params: sortObjectKeys(withToken(params)),
      })
    );

    const shouldMakeSignature =
      (process.env.REACT_APP_SIGNATURE_WEB_CHECK || "") !== "" || _get(window, `["${GLOBAL_CONFIG_KEY}"]["request_signature_required"]`);

    const signature = shouldMakeSignature
      ? await makeSignature(body, _get(window, `["${GLOBAL_CONFIG_KEY}"][${REQUEST_SIGNATURE_HEADER_NAME}]`))
      : "DEV MODE";

    const headers = {
      "Content-Type": _get(options, "headers['Content-Type']") || "application/json",
      [REQUEST_SIGNATURE_HEADER_NAME]: signature,
    };

    const apiPath = n8nVersion === 2 ? n8n2Path : n8nPath;

    const resp = await fetch(
      `${apiPath}/?m=${method}`,
      _merge(options, {
        method: "POST",
        body,
        headers,
      })
    );

    if (!resp.ok) {
      throw new Error(resp.status);
    }

    const result = await resp.json();

    if (result.error) {
      if (result.error.code === JsonRpcErrorCode.Redirect) {
        window.location.href = result.error.data.redirectURL;
      }

      throw wrapJsonRpcError(result.error);
    }

    return result.result;
  } catch (e) {
    let error = e;
    const jsonRpcError = unwrapJsonRpcError(e);
    if (jsonRpcError) {
      error = jsonRpcError;
    }

    if (error.code === JsonRpcErrorCode.Unauthorized) {
      setJWT(null);
      throw error;
    }

    throw error;
  }
}

export async function fetchWithN8n2(method, params = {}, options = {}) {
  return fetchWithN8n(method, params, options, 2);
}

const memoized = {};

export async function fetchWithMemoById(method, id, n8nVersion = 2) {
  const key = `${method}_${id}`;

  if (key in memoized) {
    return Promise.resolve(memoized[key]);
  }

  return fetchWithN8n(method, { id }, {}, n8nVersion).then(resp => {
    memoized[key] = resp;
    return resp;
  });
}

export function callPgrstRpc(path, profile, method, params = {}, options = {}) {
  const jwt = getJWT();

  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    "Content-Profile": profile,
    [REQUEST_SIGNATURE_HEADER_NAME]: _get(window, `["${GLOBAL_CONFIG_KEY}"][${REQUEST_SIGNATURE_HEADER_NAME}]`),
  };

  if (jwt) {
    headers["Authorization"] = `Bearer ${jwt}`;
  }

  return fetch(
    `${path}/${method}`,
    _merge(options, {
      method: "POST",
      body: JSON.stringify(params),
      headers,
    })
  )
    .then(res => {
      if (res.status === 204) {
        return Promise.resolve(null);
      }

      return res.json().then(pgrst => {
        if (!res.ok) {
          if (res.status === JsonRpcErrorCode.Unauthorized) {
            pgrst.code = JsonRpcErrorCode.Unauthorized;
          }

          throw wrapJsonRpcError(pgrst);
        }

        return pgrst;
      });
    })
    .catch(e => {
      let error = e;
      const jsonRpcError = unwrapJsonRpcError(e);

      if (jsonRpcError) {
        error = jsonRpcError;
      }

      if (error.code === JsonRpcErrorCode.Unauthorized) {
        setJWT(null);
        throw error;
      }

      throw error;
    });
}

export function callWebRpc(method, params = {}, options = {}) {
  return callPgrstRpc(WEB_API_PATH, "web", method, params, options);
}
